<template>
    <div class="work-bench">
        <div class="work-bench-left">
            <div class="type-box">
                <i class="iconfont">&#xe72c;</i>
                <span>快捷入口</span>
            </div>
            <div class="outlink-box">
                <div class="contain-box material-box" @click="toOutLink(1)">
                    <div class="left-content">
                        <div class="top-content">素材查找</div>
                        <div class="bottom-content">热门素材实时更新</div>
                    </div>
                </div>
                <div class="contain-box script-box" @click="toOutLink(2)">
                    <div class="left-content">
                        <div class="top-content">脚本创作</div>
                        <div class="bottom-content">脚本模板套用更快捷</div>
                    </div>
                </div>
                <div class="contain-box video-box" @click="toOutLink(3)">
                    <div class="left-content">
                        <div class="top-content">视频创作</div>
                        <div class="bottom-content">创作视频多平台发布</div>
                    </div>
                </div>
                <div class="contain-box textPic-box" @click="toOutLink(4)">
                    <div class="left-content">
                        <div class="top-content">图文创作</div>
                        <div class="bottom-content">高效编辑文本</div>
                    </div>
                </div>
                <div class="contain-box dataAnalysis-box" @click="toOutLink(5)">
                    <div class="left-content">
                        <div class="top-content">数据分析</div>
                        <div class="bottom-content">掌握红利流量趋势</div>
                    </div>
                </div>
            </div>
            <div class="type-box">
                <i class="iconfont">&#xe66f;</i>
                <span>任务专栏</span>
            </div>
            <div class="work-task">
                <div class="task">
                    <div class="view-more" @click="viewMore(1)">查看更多 ></div>
                    <el-tabs v-model="taskTab" @tab-click="changeTab">
                        <el-tab-pane label="教师任务" name="teacher">
                            <div class="task-list" v-if="teacherTaskList.length != 0">
                                <div class="list-item" v-for="item in teacherTaskList" @click="viewTaskDetail(item)">
                                    <div class="container">
                                        <div class="item-cover">
                                            <img :src="item.task_cover" alt="">
                                            <img v-if="item.task_type === 1"
                                                 src="../../../assets/img/image/teacher-task-1.png" class="sign" alt="">
                                            <img v-if="item.task_type === 3"
                                                 src="../../../assets/img/image/teacher-task-3.png" class="sign" alt="">
                                            <img v-if="item.task_type === 4"
                                                 src="../../../assets/img/image/teacher-task-4.png" class="sign" alt="">
                                        </div>
                                        <div class="item-content">
                                            <span class="item-title">{{item.task_title}}</span>
                                            <div class="item-info">
                                                <div class="item-author">
                                                    <span>发布时间：{{item.create_time}}</span>
                                                </div>
                                                <div class="item-evaluate">
                                                    <span class="evaluate-num">参与人数:{{item.join_num}}人</span>
                                                    <div class="evaluate-right">悬赏金额：{{item.task_money}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item-status">
                                            <span class="view-task" v-if="item.state === 2">查看任务 ></span>
                                            <span class="finish-task" v-if="item.state === 1">已完成</span>
                                            <span class="time-out-task" v-if="item.state === 0">已截止</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="task-list no-task-list">
                                <span>暂无数据</span>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="企业任务" name="company">
                            <div class="task-list" v-if="companyTaskList.length != 0">
                                <div class="list-item" v-for="item in companyTaskList" @click="viewTaskDetail(item)">
                                    <div class="container">
                                        <div class="item-cover">
                                            <img :src="item.task_cover" alt="">
                                            <img v-if="item.enroll === '已报名'"
                                                 src="../../../assets/img/image/applyed.png" class="sign" alt="">
                                            <img v-if="item.enroll === '需报名'"
                                                 src="../../../assets/img/image/need-apply.png" class="sign" alt="">
                                        </div>
                                        <div class="item-content">
                                            <span class="item-title">{{item.task_title}}</span>
                                            <div class="item-info">
                                                <div class="item-author">
                                                    <span>发布时间：{{item.create_time}}</span>
                                                </div>
                                                <div class="item-evaluate">
                                                    <span class="evaluate-num">参与人数:{{item.join_num}}人</span>
                                                    <div class="evaluate-right">悬赏金额：{{item.task_money}}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="item-status">
                                            <span class="view-task" v-if="item.state === 2">查看任务 ></span>
                                            <span class="finish-task" v-if="item.state === 1">已完成</span>
                                            <span class="time-out-task" v-if="item.state === 0">已截止</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-else class="task-list no-task-list">
                                <span>暂无数据</span>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="task-right">
                    <div class="show-right">
                        <div class="show-name">
                            <span v-if="activeTable">推荐榜</span>
                            <span v-else>高佣榜</span>
                        </div>
                        <div class="show-switch" @click="switchTaskTable(activeTable)">
                            <div class="switch-icon">
                                <i class="iconfont">&#xe65f;</i>
                            </div>
                            <div class="switch-text">切换</div>
                        </div>
                    </div>
                    <div class="task-right-box box1">
                        <el-table
                                style="width: 100%; margin-top: 10px;"
                                @row-click="viewTaskDetail"
                                class="rank-table" :data="taskRightList" size="medium"
                                :header-cell-style="{fontWeight: 'normal', color: '#333',background:'#EFEEFF'}"
                                :cell-style="{fontSize: '14px',color: '#333',height:'50px',cursor:'pointer'}">
                            <el-table-column
                                    align="left"
                                    prop="rank"
                                    label="排行"
                                    width="100">
                                <template slot-scope="scope">
                                    <div class="icon-background">
                                        <img src="../../../assets/img/image/workRank1.png"
                                             v-if="scope.row.rank === 1" alt="">
                                        <img src="../../../assets/img/image/workRank2.png"
                                             v-if="scope.row.rank === 2" alt="">
                                        <img src="../../../assets/img/image/workRank3.png"
                                             v-if="scope.row.rank === 3" alt="">
                                    </div>
                                    <div class="rank-title">{{scope.row.rank}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column align="left" prop="task_title" label="任务名称" width='300'>
                                <template slot-scope="scope">
                                    <div class="task-title">{{scope.row.task_title}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column v-if="activeTable" align="center" prop="enroll_num" label="报名数"
                                             width='100'>
                                <template slot-scope="scope">
                                    <span>{{scope.row.enroll_num?scope.row.enroll_num:'暂无'}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column v-else align="center" prop="task_money" label="佣金" width='100'>
                                <template slot-scope="scope">
                                    <span>{{scope.row.task_money?scope.row.task_money:'暂无'}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
            <div class="type-box">
                <i class="iconfont">&#xe66e;</i>
                <span>排行榜</span>
            </div>
            <div class="left-item">
                <div class="table-content">
                    <div class="table-box">
                        <el-table
                                style="width: 100%; margin-top: 10px;max-height: 676px"
                                class="rank-table" :data="videoRankList" size="medium"
                                @row-click="viewVideo"
                                :header-cell-style="{fontWeight: 'normal', color: '#333'}"
                                :cell-style="{fontSize: '14px',color: '#333',cursor:'pointer'}">
                            <el-table-column
                                    align="left"
                                    prop="rank"
                                    label="排行"
                                    width="100">
                                <template slot-scope="scope">
                                    <div class="icon-background">
                                        <img src="../../../assets/img/image/workRank1.png"
                                             v-if="scope.row.rank === 1" alt="">
                                        <img src="../../../assets/img/image/workRank2.png"
                                             v-if="scope.row.rank === 2" alt="">
                                        <img src="../../../assets/img/image/workRank3.png"
                                             v-if="scope.row.rank === 3" alt="">
                                    </div>
                                    <div class="rank-title">{{scope.row.rank}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="video_author" width="200" label="视频信息" align="left "
                                             class-name="student-name">
                                <template slot-scope="scope">
                                    <div class="showing">
                                        <img class="imgBox" :src=scope.row.cover_url>
                                        <span class="showing-name">{{scope.row.desc}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="play_count" label="播放量" align="center" class-name="student-name">
                                <template slot-scope="scope">
                                    <span class="student-name-title">{{scope.row.play_count}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="digg_count" label="点赞量" align="center" class-name="student-name">
                                <template slot-scope="scope">
                                    <span class="student-name-title">{{scope.row.digg_count}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-content">
                    <div class="table-box">
                        <el-table
                                style="width: 100%; margin-top: 10px;max-height: 676px"
                                class="rank-table" :data="picRankList" size="medium"
                                @row-click="viewPic"
                                :header-cell-style="{fontWeight: 'normal', color: '#333'}"
                                :cell-style="{fontSize: '14px',color: '#333',cursor:'pointer'}">
                            <el-table-column
                                    prop="index"
                                    label="排行"
                                    align="left"
                                    width="100"
                                    class-name="student-name"
                            >
                                <template slot-scope="scope">
                                    <div class="icon-background">
                                        <img src="../../../assets/img/image/workRank1.png"
                                             v-if="scope.row.rank === 1" alt="">
                                        <img src="../../../assets/img/image/workRank2.png"
                                             v-if="scope.row.rank === 2" alt="">
                                        <img src="../../../assets/img/image/workRank3.png"
                                             v-if="scope.row.rank === 3" alt="">
                                    </div>
                                    <div class="rank-title">{{scope.row.rank}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="video_author" width="200" label="图文信息" align="left "
                                             class-name="student-name">
                                <template slot-scope="scope">
                                    <div class="showing">
                                        <span class="showing-name">{{scope.row.title}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="view_count" label="阅读量" align="center" class-name="student-name">
                                <template slot-scope="scope">
                                    <span class="student-name-title">{{scope.row.view_count}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="likes_count" label="点赞量" align="center" class-name="student-name">
                                <template slot-scope="scope">
                                    <span class="student-name-title">{{scope.row.likes_count}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="fans" label="粉丝数" align="center" class-name="student-name">
                                <template slot-scope="scope">
                                    <span class="student-name-title">{{scope.row.fans}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
                <div class="table-content">
                    <div class="table-box">
                        <el-table
                                style="width: 100%; margin-top: 10px;max-height: 676px"
                                @row-click="gotoData"
                                class="rank-table" :data="fansRankList" size="medium"
                                :header-cell-style="{fontWeight: 'normal', color: '#333'}"
                                :cell-style="{fontSize: '14px',color: '#333',cursor:'pointer'}">
                            <el-table-column
                                    align="left"
                                    prop="rank"
                                    label="排行"
                                    width="100">
                                <template slot-scope="scope">
                                    <div class="icon-background">
                                        <img src="../../../assets/img/image/workRank1.png"
                                             v-if="scope.row.rank === 1" alt="">
                                        <img src="../../../assets/img/image/workRank2.png"
                                             v-if="scope.row.rank === 2" alt="">
                                        <img src="../../../assets/img/image/workRank3.png"
                                             v-if="scope.row.rank === 3" alt="">
                                    </div>
                                    <div class="rank-title">{{scope.row.rank}}</div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="video_author" width="200" label="账号信息" align="left "
                                             class-name="student-name">
                                <template slot-scope="scope">
                                    <div class="showing">
                                        <img class="imgBox" :src=scope.row.avatar>
                                        <span class="showing-name">{{scope.row.nickname}}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    align="center"
                                    prop="price"
                                    label="粉丝数"
                                    width="200">
                                <template slot-scope="scope">
                                    <span class="select">{{scope.row.fans_num}}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {Swiper, SwiperSlide, directive} from 'vue-awesome-swiper'
    import 'swiper/css/swiper.css'

    export default {
        components: {
            Swiper,
            SwiperSlide
        },
        directives: {
            swiper: directive
        },
        name: "WorkBench",
        data() {
            return {
                id: 0,
                teacherTaskList: [],
                companyTaskList: [],
                //排行商品列表
                rankTableData: [],
                taskTab: "teacher",
                tableTab: "douyin",
                notice_data: [],
                helpList: [],
                swiperOption: {
                    direction: 'vertical',
                    autoplay: true,
                },
                //排序
                amountCurrent: '',
                activeTable: true,//true为推荐榜
                taskRightList: [],
                videoRankList: [],
                picRankList: [],
                fansRankList: [],
            }
        },
        mounted() {
            this.getNoticeData();
            this.getHelpList();
            this.getTeacherTask();
            this.getCompanyTask();
            this.getTaskRightList(1);
            this.getAllClass();
            this.getVideoData();
            this.getFansRank();
        },
        methods: {
            getAllClass() {
                this.$httpStudent.axiosGet(this.$api.api_articleRankingList, res => {
                    if (res.code == 200) {
                        this.picRankList = res.data
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });
                    }
                });
            },
            getVideoData() {
                this.$httpStudent.axiosGet(this.$api.account_videoRanking, res => {
                    if (res.code == 200) {
                        this.videoRankList = res.data;
                        let len = this.videoRankList.length;
                        for (let i = 0; i < len; i++) {
                            this.videoRankList[i].rank = i + 1;
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });
                    }
                });
            },
            //排序
            toggleAmount(val) {
                this.amountCurrent = val;
                this.getDyGoodsList();
            },
            getTeacherTask() {
                Promise.all(([
                    this.getTeacherTaskList(2),
                    this.getTeacherTaskList(0),
                    this.getTeacherTaskList(1),
                ])).then(([res1, res2, res3]) => {
                    if (res1.length >= 3) {
                        this.teacherTaskList = res1;
                    } else {
                        res1.forEach(item => {
                            if (item) {
                                this.teacherTaskList.push(item)
                            }
                        })
                        let len = 3 - res1.length;
                        if (res2.length > len) {
                            for (let i = 0; i < len; i++) {
                                this.teacherTaskList.push(res2[i])
                            }
                        } else {
                            res2.forEach(item => {
                                if (item) {
                                    this.teacherTaskList.push(item)
                                }
                            })

                            let newLen = 3 - this.teacherTaskList.length;
                            if (res3.length > newLen) {
                                for (let i = 0; i < newLen; i++) {
                                    this.teacherTaskList.push(res3[i])
                                }
                            } else {
                                res3.forEach(item => {
                                    if (item) {
                                        this.teacherTaskList.push(item)
                                    }
                                })
                            }
                        }
                    }
                    let len = this.teacherTaskList.length;
                    for (let i = 0; i < len; i++) {
                        for (let j = i + 1; j < len; j++) {
                            if (this.teacherTaskList[i].task_id == this.teacherTaskList[j].task_id) { //第一个等同于第二个，splice方法删除第二个
                                this.teacherTaskList.splice(j, 1)
                                j--;
                            }
                        }
                    }
                }).catch(err => {
                });
            },
            getCompanyTask() {
                Promise.all(([
                    this.getCompanyTaskList(2),
                    this.getIntelligentList(),
                    this.getCompanyTaskList(0),
                    this.getCompanyTaskList(1),
                ])).then(([res1, res2, res3, res4]) => {
                    if (res1.length >= 3) {
                        this.companyTaskList = res1;
                    } else {
                        res1.forEach(item => {
                            if (item) {
                                this.companyTaskList.push(item)
                            }
                        })
                        let len = 3 - res1.length;
                        if (res2.length > len) {
                            for (let i = 0; i < len; i++) {
                                this.companyTaskList.push(res2[i])
                            }
                        } else {
                            res2.forEach(item => {
                                if (item) {
                                    this.companyTaskList.push(item)
                                }
                            })

                            let newLen = 3 - this.companyTaskList.length;
                            if (res3.length > newLen) {
                                for (let i = 0; i < newLen; i++) {
                                    this.companyTaskList.push(res3[i])
                                }
                            } else {
                                res3.forEach(item => {
                                    if (item) {
                                        this.companyTaskList.push(item)
                                    }
                                })
                                let newLen1 = 3 - this.companyTaskList.length;
                                if (res4.length > newLen1) {
                                    for (let i = 0; i < newLen1; i++) {
                                        this.companyTaskList.push(res4[i])
                                    }
                                } else {
                                    res4.forEach(item => {
                                        if (item) {
                                            this.companyTaskList.push(item)
                                        }
                                    })
                                }
                            }
                        }
                    }
                    let len = this.companyTaskList.length;
                    for (let i = 0; i < len; i++) {
                        for (let j = i + 1; j < len; j++) {
                            if (this.companyTaskList[i].task_id == this.companyTaskList[j].task_id) { //第一个等同于第二个，splice方法删除第二个
                                this.companyTaskList.splice(j, 1)
                                j--;
                            }
                        }
                    }
                }).catch(err => {
                });
            },
            // 获取任务列表
            getTeacherTaskList(val) {
                return new Promise((resolve, reject) => {
                    let param = {
                        type: 1,
                        page: 1,
                        limit: 3,
                        task_state: val
                    }
                    this.$httpStudent.axiosGetBy(this.$api.studentTaskList, param, res => {
                        if (res.code == 200) {
                            resolve(res.list);
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg,
                                duration: 1000
                            });
                        }
                    }, (err) => {
                    })
                });
            },
            getIntelligentList() {
                return new Promise((resolve, reject) => {
                    let param = {
                        page: 1,
                        limit: 3
                    }
                    this.$httpStudent.axiosGetBy(this.$api.intelligent_getTaskCooperationList, param, res => {
                        if (res.code == 200) {
                            resolve(res.data.data);
                        } else {
                            this.$message({
                                type: 'error',
                                message: res.msg
                            });
                        }
                    })
                });
            },
            getCompanyTaskList(val) {
                return new Promise((resolve, reject) => {
                    let param = {
                        type: 2,
                        page: 1,
                        limit: 3,
                        task_state: val
                    }
                    this.$httpStudent.axiosGetBy(this.$api.studentTaskList, param, res => {
                        if (res.code == 200) {
                            resolve(res.list);
                        } else {
                            this.$message({
                                type: "error",
                                message: res.msg,
                                duration: 1000
                            });
                        }
                    }, (err) => {
                    })
                });
            },
            getTaskRightList(val) {
                let param = {
                    type: 2,
                    page: 1,
                    limit: 6,
                    task_money: 'desc',
                    enroll: 'desc',
                }
                if (val == 1) {
                    delete param.task_money
                } else {
                    delete param.enroll
                }
                this.$httpStudent.axiosGetBy(this.$api.studentTaskList, param, res => {
                    if (res.code == 200) {
                        this.taskRightList = res.list;
                        let len = this.taskRightList.length;
                        this.taskRightList.forEach(item => {
                            if (item.task_is_apply === 3) {
                                item.enroll_num = item.task_number_num
                            }
                        })
                        if (val == 1) {
                            this.taskRightList.sort(function (a, b) {
                                return b.enroll_num - a.enroll_num
                            })
                        }
                        this.taskRightList.forEach((item, index) => {
                            item.rank = index + 1;
                        })
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });
                    }
                }, (err) => {
                })
            },
            getFansRank() {
                this.$httpStudent.axiosGet(this.$api.account_fansRanking, res => {
                    if (res.code == 200) {
                        this.fansRankList = res.data;
                        let len = this.fansRankList.length;
                        for (let i = 0; i < len; i++) {
                            this.fansRankList[i].rank = i + 1;
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });
                    }
                }, (err) => {
                })
            },
            viewVideo(item) {
                window.open(item.video_url, '_blank')
            },
            viewPic(item) {
                window.open(item.article_url, '_blank')
            },
            gotoData(row) {
                if (row.platform_type === 1) {
                    this.$router.push({
                        path: '/student/data/details',
                        query: {
                            id: row.id,
                            platform: 1,
                            type: 1
                        }
                    })

                } else {
                    let param = {
                        type: 2,
                        platform_type: 7,
                        id: row.id,
                    };
                    this.$httpStudent.axiosGetBy(this.$api.get_account, param, res => {
                        if (res.code === 200) {
                            window.open(res.data.url)
                        } else if (res.code === 204) {
                            this.$message.error(res.message);
                        }
                    }, (err) => {
                        console.log(err);
                    })

                }
            },
            //查看任务详情
            viewTaskDetail(taskItem) {
                if (taskItem.userData) {
                    this.$router.push({
                        path: '/student/task/detail',
                        query: {
                            id: taskItem.task_id,
                            user_id: taskItem.userData[0].user_account_id
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/task/detail',
                        query: {
                            id: taskItem.task_id
                        }
                    })
                }
                this.$emit('changeMenu', 'studentTaskIndex');
            },
            changeTab() {
                let val = this.taskTab === 'teacher' ? 1 : 2;
                if (val == 1) {
                    this.teacherTaskList = [];
                    this.getTeacherTask()
                } else {
                    this.companyTaskList = [];
                    this.getCompanyTask()
                }
            },
            getNoticeData() {
                this.$httpStudent.axiosGet(this.$api.pa, res => {
                    if (res.code == 200) {
                        this.notice_data = res.data;
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            getHelpList() {
                this.$httpStudent.axiosGet(this.$api.help, res => {
                    if (res.code == 200) {
                        this.helpList = res.data.data;
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            viewMore(val) {
                if (val == 3) {
                    this.$router.push('/student/dy/products/rank');
                    this.$emit('changeMenu', 'studentDyProductsRank');
                } else {
                    this.$emit('changeMenu', 'studentTaskIndex');
                    this.$router.push({
                        path: '/student/task/index',
                        query: {
                            taskTab: this.taskTab
                        }
                    });
                }
            },
            toOutLink(val) {
                switch (val) {
                    case 1:
                        this.$router.push('/student/hot/scripts');
                        this.$emit('changeMenu', 'studentHot');
                        break;
                    case 2:
                        this.$router.push('/student/script/creation');
                        this.$emit('changeMenu', 'studentScriptCreation');
                        break;
                    case 3:
                        this.$router.push('/student/video/clip');
                        this.$emit('changeMenu', 'studentVideoClip');
                        break;
                    case 4:
                        this.$router.push('/student/graphic/creation');
                        this.$emit('changeMenu', 'studentGraphicIndex');
                        break;
                    case 5:
                        this.$router.push('/student/dataAnalysis/index');
                        this.$emit('changeMenu', 'studentDataAnalysis');
                        break;
                }
            },
            switchTaskTable(val) {
                this.activeTable = !val;
                let param = val ? 0 : 1;
                this.getTaskRightList(param)
            },
        }
    }
</script>

<style scoped lang="scss">
    h3, p {
        margin: 0;
        font-weight: 400;
    }

    .work-bench {
        display: flex;
        justify-content: start;
        height: 100%;
        min-width: 1400px;

        ::v-deep .el-tab-pane {
            height: 100%;
        }

        ::v-deep .el-tabs {
            flex: 1;
            width: 1%;
        }

        ::v-deep .el-tabs {
            height: 100%;
            width: 100%;
            box-shadow: none;
            border: none;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-direction: column;
            flex-direction: column;
            background: transparent;
        }

        ::v-deep .el-tabs__header {
            border-bottom: none;
            margin: 0;
        }

        ::v-deep .el-tabs__header .is-active {
            font-size: 18px;
            font-weight: 400;
            color: #16113F;
        }

        ::v-deep .el-tabs__content {
            height: 1%;
            flex: 1;
            background: #fff;
            padding-top: 10px;
            padding-bottom: 0;
        }

        ::v-deep .el-tabs__item {
            height: 52px;
            line-height: 52px;
            font-size: 18px;
            border: none;
            color: #5F5C74;
        }

        ::v-deep .el-tabs__active-bar {
            background: #9ACC5B;
        }

        ::v-deep .el-tabs__active-bar:before {
            position: absolute;
            top: -13px;
            left: 40%;
            content: '';
            width: 0;
            height: 0;
            border-style: dashed dashed solid dashed;
            border-width: 7px;
            border-color: transparent transparent #9ACC5B transparent;
        }

        .work-bench-left {
            display: flex;
            flex-direction: column;
            width: 1%;
            flex: 1;
            height: 1%;

            .type-box {
                i {
                    color: #685FEF;
                }

                span {
                    margin-left: 7px;
                    font-size: 20px;
                    color: #16113F;
                }
            }

            .outlink-box {
                height: 108px;
                padding: 24px 0;
                display: flex;

                .left-content {
                    padding: 20px 0 42px 20px;

                    .top-content {
                        font-size: 20px;
                        color: #ffffff;
                    }

                    .bottom-content {
                        font-size: 16px;
                        color: #ffffff;
                        width: 162px;
                        margin-top: 12px;
                        opacity: 0.73;
                    }
                }

                .contain-box {
                    width: 20%;
                    height: 100%;
                    margin-right: 16px;
                    border-radius: 9px;
                    display: flex;
                    overflow: hidden;
                    cursor: pointer;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;

                    &:nth-of-type(5) {
                        margin-right: 0;
                    }
                }

                .material-box {
                    background-image: url("../../../assets/img/image/material-box.png");
                }

                .script-box {
                    background-image: url("../../../assets/img/image/script-box.png");
                }

                .video-box {
                    background-image: url("../../../assets/img/image/video-box.png");
                }

                .textPic-box {
                    background-image: url("../../../assets/img/image/textPic-box.png");
                }

                .dataAnalysis-box {
                    background-image: url("../../../assets/img/image/data-analysis.png");
                }
            }

            .work-task {
                display: flex;

                .task {
                    height: 424px;
                    background: #ffffff;
                    border-radius: 10px;
                    margin: 24px 0;
                    padding: 10px 20px 0;
                    display: flex;
                    width: 70%;
                    position: relative;

                    .view-more {
                        position: absolute;
                        text-align: center;
                        font-size: 18px;
                        color: #5E53F0;
                        margin: 22px 0 18px 0;
                        cursor: pointer;
                        right: 20px;
                        top: 0;
                        z-index: 1;
                    }
                }

                .task-right {
                    width: 33%;
                    height: 434px;
                    background: #ffffff;
                    border-radius: 10px;
                    margin: 24px 0 24px 20px;
                    display: flex;
                    flex-direction: column;

                    .show-right {
                        display: flex;
                        justify-content: space-between;
                        padding: 20px;

                        .show-name {
                            font-size: 18px;
                            color: #16113F;
                        }

                        .show-switch {
                            color: #16113F;
                            display: flex;
                            justify-content: space-between;
                            cursor: pointer;

                            .switch-icon {
                                transform: rotate(90deg);
                                margin-right: 6px;
                            }

                            .switch-text {
                                font-size: 14px;
                                color: #16113F;
                            }
                        }
                    }

                    .task-right-box {
                        padding: 0 15px;
                        position: relative;
                        flex: 1;

                        .task-title {
                            max-width: 260px;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .icon-background {
                            img {
                                width: 27px;
                                height: 27px;
                            }
                        }

                        .rank-title {
                            position: absolute;
                            left: 20%;
                            top: 25%;
                        }
                    }
                }

                .task-list {
                    display: flex;
                    flex-wrap: wrap;
                    height: 100%;
                    min-height: 360px;
                    justify-content: flex-start;
                    overflow: hidden;

                    .list-item {
                        width: calc(33% - 10px);
                        height: 100%;
                        margin-right: 16px;

                        &:nth-of-type(3) {
                            margin-right: 0;
                        }

                        .container {
                            width: 100%;
                            border: 1px solid #E3E4EC;
                            transition: all .25s ease-out;
                            border-radius: 8px;
                            cursor: pointer;

                            .item-cover {
                                position: relative;
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 100%;
                                height: 200px;

                                img {
                                    max-width: 100%;
                                    max-height: 100%;
                                    /*border-radius:8px 8px 0 0;*/
                                }

                                img.sign {
                                    position: absolute;
                                    border-radius: 0;
                                    top: 25px;
                                    left: 0;
                                    opacity: .8;
                                    width: 80px;
                                }
                            }

                            .item-content {
                                padding: 10px 0 0 16px;

                                .item-title {
                                    display: block;
                                    overflow: hidden;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                    color: #3A3567;
                                    font-size: 16px;
                                }

                                .item-info {
                                    margin-top: 10px;

                                    .item-author {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;

                                        span {
                                            font-size: 14px;
                                            color: #A6A0C3;
                                        }

                                        .item-avatar {
                                            display: flex;
                                            align-items: center;
                                            margin: 10px 0;

                                            img {
                                                width: 24px;
                                                height: 24px;
                                                margin-right: 10px;
                                                border-radius: 50%;
                                            }

                                            span {
                                                display: block;
                                                width: 50px;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                            }
                                        }
                                    }

                                    .item-evaluate {
                                        display: flex;
                                        justify-content: space-between;
                                        align-items: center;
                                        font-size: 14px;
                                        margin-bottom: 10px;
                                        color: #3A3567;

                                        i {
                                            color: #F19149;
                                            margin-right: 2px;
                                        }

                                        .evaluate-right {
                                            background: #564CED;
                                            color: #ffffff;
                                            width: 141px;
                                            height: 32px;
                                            display: flex;
                                            align-items: center;
                                            justify-content: center;
                                            font-size: 14px;
                                            position: relative;
                                        }

                                        .evaluate-right:before {
                                            position: absolute;
                                            top: 0;
                                            left: 0;
                                            content: '';
                                            width: 0;
                                            height: 0;
                                            border: 16px solid;
                                            border-color: transparent transparent transparent #ffffff;
                                        }
                                    }
                                }
                            }

                            .item-status {
                                text-align: center;
                                padding: 8px;
                                border-top: 1px solid #F8F7FF;

                                .view-task {
                                    color: #2338E6;
                                }

                                .finish-task {
                                    color: #20C997;
                                }

                                .time-out-task {
                                    color: #666666;
                                }
                            }
                        }
                    }
                }

                .no-task-list {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .left-item {
                margin: 20px 0 0;
                height: 705px;
                display: flex;
                justify-content: start;
            }

            .task-list {
                padding: 0;
                max-height: 150px;
                overflow: auto;

                .task-item {
                    display: flex;
                    padding: 15px 15px 0;
                    align-items: center;
                    border-bottom: 1px solid #eaeaea;
                    flex-wrap: wrap;

                    &:last-of-type {
                        border-bottom: none;
                    }

                    .img {
                        width: 44px;
                        height: 44px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-bottom: 15px;

                        img {
                            max-width: 100%;
                            max-height: 100%
                        }
                    }

                    .detail {
                        width: 1%;
                        flex: 1;
                        line-height: 1;
                        margin: 0 10px 15px 10px;
                        min-width: 200px;

                        .text {
                            font-size: 14px;
                            width: 100%;
                            height: 20px;

                            .title {
                                display: block;
                                max-width: calc(100% - 31px);
                                overflow: hidden;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                                float: left;
                                color: #333;
                                line-height: 1;
                            }
                        }

                        .second {
                            font-size: 12px;
                            color: #999;
                            line-height: 1.5;

                            span {
                                display: inline-block;
                                margin-right: 5px;
                            }
                        }
                    }
                }

            }

            .oper {
                margin-bottom: 15px;

                .btn-red {
                    cursor: pointer;
                }
            }
        }

        .current {
            color: #20C997;
        }

        .table-content {
            height: 100%;
            width: calc(100% - 40px);
            margin-right: 20px;
            background: #ffffff;
            border-radius: 10px;

            .table-box {
                padding: 20px;
                overflow: hidden;

                .icon-background {
                    img {
                        width: 27px;
                        height: 27px;
                    }
                }

                .rank-title {
                    position: absolute;
                    left: 20%;
                    top: 32%;
                }

                .showing {
                    display: flex;
                    align-items: center;
                    /*width: 30px;*/
                    height: 58px;

                    img {
                        display: inline-block;
                        height: 46px;
                        width: 46px;
                        margin-right: 10px;
                        /*border-radius: 50%;*/
                    }

                    .showing-name {
                        max-width: 260px;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }

            &:nth-of-type(3) {
                margin-right: 0;
            }

            .view-more {
                text-align: center;
                font-size: 18px;
                color: #5E53F0;
                margin: 22px 0 18px 0;
                cursor: pointer;
            }

            .icon-background {
                img {
                    width: 27px;
                    height: 27px;
                }
            }

            .rank-title {
                position: absolute;
                left: 48%;
                top: 25%;
            }
        }

        .clearfix {
            zoom: 1;
        }

        a {
            color: #333;
        }

        .rank-table {
            ::v-deep .is-scrolling-none {
                height: calc(100% - 48px);
            }

            .header-event {
                cursor: pointer;

                .header-icon {
                    margin-left: 6px;
                    font-size: 12px;
                    color: #A0A0A0;
                }

                &.current {
                    color: #000;

                    .header-icon {
                        color: #1E33E3;
                    }
                }
            }

            .select {
                &.current {
                    span {
                        font-weight: 700;
                        color: #333;
                    }
                }
            }

            ::v-deep .cell {

                .cell-data {
                    padding: 0 4px;
                    color: #999;
                    background: #eef2f9;
                    border-radius: 4px;
                    font-size: 14px;
                    margin: 0 5px;

                    i {
                        margin-right: 3px;
                    }

                    &.current {
                        color: #ff4c5b;
                        background-color: #fff0f1;
                    }
                }
            }

            ::v-deep .handle-btn {
                span {
                    width: 28px;
                    height: 28px;
                    display: inline-block;
                    border-radius: 50%;
                    line-height: 28px;

                    i {
                        color: #fff;
                    }
                }
            }

            ::v-deep .data-btn {
                span {
                    background-color: #465CF1;
                }
            }

            ::v-deep .user-btn {
                span {
                    background-color: #42D7AB;
                }
            }

            ::v-deep .play-btn {
                span {
                    background-color: #FF0707;
                }
            }

            ::v-deep .chats-btn {
                span {
                    background-color: #FFB624;
                }
            }

            .header-title {
                cursor: pointer;

                i {
                    font-size: 12px;
                    margin-left: 5px;
                }
            }
        }
    }
</style>
